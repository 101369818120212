<template>
  <div class="page">
    <div
      class="back-link"
      @click="datasetId ?
        $router.push({ name: 'DatasetDetail', params: { id: datasetId } }) :
        $router.push({ name: 'Data' })
      "
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ capitalizeFirstLetter(datasetId ? $tc('words.dataset', 1) : $t('words.data')) }}
      </span>
    </div>
    <div class="top">
      <b-row>
        <b-col class="left-col" />
        <b-col class="right-col">
          <b-overlay
            id="overlay-background"
            :show="reloading"
            :variant="'white'"
            :opacity="0.9"
            :blur="'2px'"
            rounded="sm"
          >
            <template #overlay>
              <div class="d-flex align-items-center">
                <b-spinner
                  small
                  variant="success"
                />
              </div>
            </template>
            <button
              class="btn btn-success btn-lg"
              @click="updateTasksList"
            >
              {{ $t('buttons.refresh') }}
            </button>
          </b-overlay>
        </b-col>
      </b-row>
    </div>
    <SearchablePaginatedTable
      :name="'tasksListTable'"
      :loading="loadingTable"
      :searchable="false"
      :route-to="'TaskDetail'"
      :fields="tasksFields"
      :rows="taskRows"
      :count="dashboardTasksCount"
      :sort-function="GET_DASHBOARD_TASKS"
      :selectable="false"
      @sorted="setTableSorted"
      @change-page="changeTablePage"
    />
  </div>
</template>

<script>
import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';

import { mapState, mapActions, mapMutations } from 'vuex';

export default {
  name: 'Tasks',

  components: {
    SearchablePaginatedTable
  },

  props: {
    datasetId: {
      type: [String, Number],
      default: null
    }
  },

  data() {
    return {
      loadingTable: false,
      reloading: false,
      tableSorted: {
        direction: null,
        field: null
      }
    };
  },

  computed: {
    ...mapState('table', [
      'currentTables'
    ]),
    ...mapState('dashboard', [
      'dashboardTasksCount',
      'dashboardTasksQueue'
    ]),
    tasksFields() {
      return [
        {
          key: 'ID',
          apiName: 'task_id',
          sortable: false,
          width: '23%',
          centered: false
        },
        {
          key: this.$t('tasks.object'),
          apiName: 'backend_object',
          sortable: false,
          width: '22%',
          centered: false
        },
        {
          key: this.$t('tasks.action'),
          apiName: 'action',
          sortable: false,
          width: '21%',
          centered: false
        },
        {
          key: this.$t('tasks.startDate'),
          apiName: 'creation_date',
          sortable: false,
          width: '10%',
          centered: false
        },
        {
          key: this.$t('tasks.endDate'),
          apiName: 'stop_date',
          sortable: false,
          width: '10%',
          centered: false
        },
        {
          key: this.$t('tasks.status'),
          apiName: 'state',
          sortable: false,
          width: '10%',
          right: false,
          centered: true
        },
        {
          key: 'Icon',
          apiName: '',
          sortable: false,
          width: '5%',
          centered: true
        }
      ];
    },
    taskRows() {
      return this.dashboardTasksQueue.map( el => {
        return {
          id: el.id,
          ID: el.task_id,
          [this.$t('tasks.object')]:
            el.backend_object && el.backend_object.label ?
              el.backend_object.label :
              ''
          ,
          [this.$t('tasks.action')]: el.action,
          [this.$t('tasks.startDate')]: el.creation_date ? new Date(el.creation_date).toLocaleString('fr-FR', {
            year: '2-digit',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }) : null,
          [this.$t('tasks.endDate')]: el.stop_date ? new Date(el.stop_date).toLocaleString('fr-FR', {
            year: '2-digit',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
          }) : null,
          [this.$t('tasks.status')]: el.state_label,
          Icon: el.state
        };
      });
    }
  },

  created() {
    this.SET_TABLE({
      name: 'tasksListTable',
      table: {
        loading: false,
        currentPage: this.currentTables.find(t => t.name === 'tasksListTable') ?
          this.currentTables.find(t => t.name === 'tasksListTable').currentPage :
          1,
        isTableSearched: false,
        searchQuery: null
      }
    });
  },

  mounted() {
    this.loadingTable = true;
    this.GET_DASHBOARD_TASKS({
      ...this.tableSorted,
      datasetId: this.datasetId
    })
      .then(() => {
        this.loadingTable = false;
      })
      .catch(() => {
        this.loadingTable = false;
      });

    this.CLEAR_RELOAD_INTERVAL_ID('dashboard_relaod');
    this.SET_RELOAD_INTERVAL_ID({
      name: 'task_reload',
      interval: setInterval(() => {
        this.updateTasksList('reload');
      }, process.env.VUE_APP_RELOAD_INTERVAL)
    });
  },

  beforeRouteLeave(to, from, next) {
    this.CLEAR_RELOAD_INTERVAL_ID('task_reload');
    next();
  },

  methods: {
    ...mapMutations([
      'SET_RELOAD_INTERVAL_ID',
      'CLEAR_RELOAD_INTERVAL_ID'
    ]),
    ...mapMutations('table', [
      'SET_TABLE'
    ]),
    ...mapActions('dashboard', [
      'GET_DASHBOARD_TASKS'
    ]),

    setTableSorted(e) {
      this.tableSorted = e;
    },

    changeTablePage(e) {
      this.loadingTable = true;
      this.GET_DASHBOARD_TASKS({
        ...this.tableSorted,
        page: e,
        datasetId: this.datasetId
      })
        .then(() => {
          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    updateTasksList(mode) {
      if (mode === 'reload') {
        this.reloading = true;
      } else {
        this.loadingTable = true;
      }
      this.GET_DASHBOARD_TASKS({
        ...this.tableSorted,
        datasetId: this.datasetId
      })
        .then(() => {
          if (mode === 'reload') {
            this.reloading = false;
          } else {
            this.loadingTable = false;
          }
        })
        .catch(() => {
          if (mode === 'reload') {
            this.reloading = false;
          } else {
            this.loadingTable = false;
          }
        });
    }
  }
};
</script>

